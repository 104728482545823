import React, { FC } from 'react';
import { graphql, PageProps } from 'gatsby';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Layout from '../../components/Layout';
import BreadCrumbs from '../../components/BreadCrumbs';

import './FaqPagePage.scss';

interface IFaqPagePageProps extends PageProps {
  pageContext: { breadcrumbs: UmbracoTypes.IBreadCrumb[] };
  data: FaqPageTypes.IFaqPageData;
}

const FaqPagePage: FC<IFaqPagePageProps> = (props) => {
  const {
    faqPage: { body, banner, lang, seoMetaTitle, seoMetaKeywords, seoMetaDescription },
    header,
    footer,
    siteSettings,
    covidBanner,
    newsletterPopupDefault,
    newsletterPopupDiscount,
    newsletterPopupFreeDelivery,
    disclaimerPopup,
    searchPath,
    brandSettings,
    legalPopup,
  } = props.data;

  return (
    <Layout
      seo={{ seoMetaTitle, seoMetaDescription, seoMetaKeywords }}
      header={header}
      footer={footer}
      siteSettings={siteSettings}
      covidBanner={covidBanner}
      newsletterPopupDefault={newsletterPopupDefault}
      newsletterPopupDiscount={newsletterPopupDiscount}
      newsletterPopupFreeDelivery={newsletterPopupFreeDelivery}
      disclaimerPopup={disclaimerPopup}
      searchPath={searchPath}
      brandSettings={brandSettings}
      legalPopup={legalPopup}
      langSettings={{
        currentLang: lang,
        langs: [{ link: lang, lang }],
      }}
    >
      <section className="article faqs">
        <Container fluid>
          <Row>
            <Col lg={{ span: 8, offset: 2 }}>
              <BreadCrumbs data={props.pageContext.breadcrumbs} />
            </Col>
          </Row>
          <Row>
            <Col lg={{ span: 8, offset: 2 }}>
              {banner && banner[0]?.properties?.title && (
                <h1 className="article-teaser__title">{banner[0]?.properties?.title}</h1>
              )}
              {body &&
                body.map((block, key) => {
                  if (!block.properties.accordion) {
                    return block.properties.text ? (
                      <div key={key} dangerouslySetInnerHTML={{ __html: block.properties.text }} />
                    ) : (
                      <></>
                    );
                  }

                  return (
                    <div>
                      {block.properties.text && (
                        <div dangerouslySetInnerHTML={{ __html: block.properties.text }} />
                      )}
                      {block.properties.accordion && (
                        <div className="accordion-links-group">
                          {block.properties.accordion.map((question, index) => {
                            if (question.properties.link) {
                              return (
                                <a
                                  key={index}
                                  className="question"
                                  href={
                                    question.properties.link[0]
                                      ? question.properties.link[0].url
                                      : '#'
                                  }
                                >
                                  {question.properties.label}
                                </a>
                              );
                            }

                            return <></>;
                          })}
                        </div>
                      )}
                    </div>
                  );
                })}
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  );
};

export const query = graphql`
  query($lang: String!, $link: String!) {
    faqPage(link: { eq: $link }, lang: { eq: $lang }) {
      ...FragmentSeo
      lang
      pageName
      body {
        properties {
          accordion {
            properties {
              label
              link {
                url
                name
              }
            }
          }
          text
        }
      }
      banner {
        properties {
          title
        }
      }
    }
    ...FragmentCommonCompositions
  }
`;

export default FaqPagePage;
